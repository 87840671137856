import React, { useEffect, useRef, useState } from "react";

// Component để quản lý cuộc gọi video
const VideoCall = () => {
    const localVideoRef = useRef(null);
    const [peerConnections, setPeerConnections] = useState({});
    const signalingServerRef = useRef(null);
    const [localStream, setLocalStream] = useState(null);
    const [screenStream, setScreenStream] = useState(null);  // Quản lý luồng chia sẻ màn hình
    const [remoteStreams, setRemoteStreams] = useState([]);
    const [isMicOn, setIsMicOn] = useState(true);  // Quản lý trạng thái micro
    const [isCamOn, setIsCamOn] = useState(true);  // Quản lý trạng thái camera
    const [isScreenSharing, setIsScreenSharing] = useState(false); // Trạng thái chia sẻ màn hình

    // Khi component được khởi chạy, thiết lập kết nối video
    useEffect(() => {
        const signalingServer = new WebSocket('wss://ws.3pacademy.vn/signaling');
        signalingServerRef.current = signalingServer;

        signalingServer.onmessage = async (message) => {
            const data = JSON.parse(message.data);
            const peerId = data.peerId;
            let peerConnection = peerConnections[peerId];

            if (data.offer) {
                peerConnection = createPeerConnection(peerId);
                await peerConnection.setRemoteDescription(new RTCSessionDescription(data.offer));
                const answer = await peerConnection.createAnswer();
                await peerConnection.setLocalDescription(answer);
                signalingServer.send(JSON.stringify({ answer, peerId }));
            } else if (data.answer) {
                await peerConnection.setRemoteDescription(new RTCSessionDescription(data.answer));
            } else if (data.candidate) {
                await peerConnection.addIceCandidate(new RTCIceCandidate(data.candidate));
            }
        };

        startVideoCall();

        return () => {
            // Clean up khi component bị hủy bỏ
            signalingServer.close();
            if (localStream) {
                localStream.getTracks().forEach(track => track.stop());
            }
            Object.values(peerConnections).forEach(pc => pc.close());
        };
    }, []);

    const startVideoCall = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
            localVideoRef.current.srcObject = stream;
            setLocalStream(stream);

            signalingServerRef.current.onopen = () => {
                signalingServerRef.current.send(JSON.stringify({ join: true }));
            };
        } catch (error) {
            console.error('Error accessing media devices.', error);
        }
    };

    const createPeerConnection = (peerId) => {
        const peerConnection = new RTCPeerConnection();

        // Thêm track từ luồng video cục bộ
        localStream.getTracks().forEach(track => peerConnection.addTrack(track, localStream));

        peerConnection.onicecandidate = (event) => {
            if (event.candidate) {
                signalingServerRef.current.send(JSON.stringify({ candidate: event.candidate, peerId }));
            }
        };

        peerConnection.ontrack = (event) => {
            setRemoteStreams(prevStreams => {
                if (!prevStreams.find(stream => stream.id === event.streams[0].id)) {
                    return [...prevStreams, event.streams[0]];
                }
                return prevStreams;
            });
        };

        setPeerConnections(prevConnections => ({
            ...prevConnections,
            [peerId]: peerConnection,
        }));

        return peerConnection;
    };

    const handleNewPeer = (peerId) => {
        const peerConnection = createPeerConnection(peerId);
        peerConnection.createOffer().then(offer => {
            peerConnection.setLocalDescription(offer);
            signalingServerRef.current.send(JSON.stringify({ offer, peerId }));
        });
    };

    const handlePeerLeave = (peerId) => {
        const peerConnection = peerConnections[peerId];
        if (peerConnection) {
            peerConnection.close();
            setPeerConnections(prevConnections => {
                const updatedConnections = { ...prevConnections };
                delete updatedConnections[peerId];
                return updatedConnections;
            });
            setRemoteStreams(prevStreams => prevStreams.filter(stream => stream.peerId !== peerId));
        }
    };

    // Bật/tắt micro
    const toggleMicrophone = () => {
        if (localStream) {
            const audioTrack = localStream.getAudioTracks()[0];
            if (audioTrack) {
                audioTrack.enabled = !audioTrack.enabled;
                setIsMicOn(audioTrack.enabled);
            }
        }
    };

    // Bật/tắt camera
    const toggleCamera = () => {
        if (localStream) {
            const videoTrack = localStream.getVideoTracks()[0];
            if (videoTrack) {
                videoTrack.enabled = !videoTrack.enabled;
                setIsCamOn(videoTrack.enabled);
            }
        }
    };

    // Bắt đầu chia sẻ màn hình
    const startScreenShare = async () => {
        try {
            const stream = await navigator.mediaDevices.getDisplayMedia({ video: true });
            setScreenStream(stream);
            setIsScreenSharing(true);

            // Thay thế video track của camera bằng màn hình chia sẻ
            const videoTrack = stream.getVideoTracks()[0];
            const senders = getSendersFromPeerConnections();

            senders.forEach(sender => {
                if (sender.track.kind === 'video') {
                    sender.replaceTrack(videoTrack);
                }
            });

            // Cập nhật màn hình chia sẻ vào video của người dùng
            localVideoRef.current.srcObject = stream;

            // Khi dừng chia sẻ màn hình
            stream.getVideoTracks()[0].onended = () => {
                stopScreenShare();
            };
        } catch (error) {
            console.error("Error sharing screen: ", error);
        }
    };

    // Dừng chia sẻ màn hình và quay lại camera
    const stopScreenShare = () => {
        if (screenStream) {
            screenStream.getTracks().forEach(track => track.stop());
            setScreenStream(null);
            setIsScreenSharing(false);

            const videoTrack = localStream.getVideoTracks()[0];
            const senders = getSendersFromPeerConnections();

            senders.forEach(sender => {
                if (sender.track.kind === 'video') {
                    sender.replaceTrack(videoTrack);
                }
            });

            // Khôi phục lại video của người dùng từ camera
            localVideoRef.current.srcObject = localStream;
        }
    };

    // Lấy tất cả các senders từ peer connections
    const getSendersFromPeerConnections = () => {
        return Object.values(peerConnections).flatMap(pc => pc.getSenders());
    };

    return (
        <div>
            <video ref={localVideoRef} autoPlay playsInline muted />
            <div id="remoteVideosContainer">
                {remoteStreams.map((stream, index) => (
                    <video key={index} autoPlay playsInline srcObject={stream} />
                ))}
            </div>

            <div>
                {/* Nút bật/tắt micro */}
                <button onClick={toggleMicrophone}>
                    {isMicOn ? "Tắt Micro" : "Bật Micro"}
                </button>

                {/* Nút bật/tắt camera */}
                <button onClick={toggleCamera}>
                    {isCamOn ? "Tắt Camera" : "Bật Camera"}
                </button>

                {/* Nút chia sẻ màn hình */}
                <button onClick={isScreenSharing ? stopScreenShare : startScreenShare}>
                    {isScreenSharing ? "Dừng chia sẻ màn hình" : "Chia sẻ màn hình"}
                </button>
            </div>
        </div>
    );
};

export default VideoCall;
